<template>

  <div>

   <b-row >
    <b-col cols="6" sm="6" md="6" lg="6" xl="6" style="margin-bottom:0px; ">

    <filtrar-precio-suscripcion :token-auth="tokenAuth" />

  
    </b-col>

    <b-col cols="6" sm="6" md="6" lg="6" xl="6" style="margin-bottom:0px; ">


  <filtrar-rating-suscripcion :token-auth="tokenAuth" />



</b-col>


<b-col cols="6" sm="6" md="6" lg="6" xl="6" style="margin-bottom:0px; ">



  <filtrar-recurrencia-suscripcion :token-auth="tokenAuth" />
</b-col>

<b-col cols="6" sm="6" md="6" lg="6" xl="6" style="margin-bottom:0px; ">



<filtrar-comision-suscripcion :token-auth="tokenAuth" />
</b-col>

    <b-col cols="12" sm="12" md="12" lg="12" xl="12">

      <p style="text-align:left;font-size:14px; margin-top: 10px;    line-height: 16px;"> Filtrar por categorias:</p>

 
    </b-col>

    </b-row>

    <b-row >

      <b-col  sm="12" md="12" lg="12" xl="12" >

      <div class="search-input" >


              <b-form-input
              ref="searchInput"
                  v-model="searchQuery"
                  placeholder="Buscar categoria"
                  autocomplete="off"
              />
        <div class="search-input-close">


          <div style="cursor:pointer;margin-right: 20px;   display: inline-block !important;"  @click="clear">
          <feather-icon icon="XIcon" size="18"  class="clear_icon" :class="{'show_clear': searchQuery}"/>
              
          </div>


          </div>

          </div>

          </b-col>


    <b-col   cols="12" sm="12" md="12" lg="12" xl="12" class="scroll_div_perfil" tyle="padding: 0px;">
    
      <p style="text-align:left;font-size:12px; margin-top: 0px;  font-weight: bold;  line-height: 16px;"> Listado de categorias:</p>

      <div v-if="startSearch">

          <div v-if="items.length != 0">

            <div class="inputGroup"  v-for="item in items" :key="item.name">
                <input :id="item.name" v-model="category" :disabled="isDisabled"  @change="onChangeCategory($event)" :value="item.id" type="radio"/>
                <label :for="item.name">{{ item.name }}</label>
              </div>

          </div>




                <div  v-else align="center">

              
              <svg width="100" height="100" viewBox="-20 0 190 190"  xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M38.155 140.475 48.988 62.1108l43.881 4.946 18.568 23.955-8.041 57.1092L38.155 140.475zM84.013 94.0018 88.827 71.8068l-34.781-3.5-9.854 67.1502L98.335 142.084l6.542-45.2752-20.864-2.807zM59.771 123.595C59.394 123.099 56.05 120.299 55.421 119.433 64.32 109.522 86.05 109.645 92.085 122.757 91.08 123.128 86.59 125.072 85.71 125.567 83.192 118.25 68.445 115.942 59.771 123.595zM76.503 96.4988 72.837 99.2588l-5.515-6.642L59.815 96.6468l-3.029-5.069L63.615 88.1508l-4.526-5.452L64.589 79.0188 68.979 85.4578 76.798 81.5328 79.154 86.2638l-7.047 3.783 4.396 6.452z" fill="#4a4a4a"/></svg>
              <p style="text-align:center;font-size:16px">No se encontraron resultados.</p>

            
            </div>
              


            </div>


    <div v-else class="inputGroup" v-for="item in items" :key="item.name">
                <input :id="item.name" v-model="category" :disabled="isDisabled"  @change="onChangeCategory($event)" :value="item.id" type="radio"/>
                <label :for="item.name">{{ item.name }}</label>
              </div>


    </b-col>



    </b-row>

         
  </div>   
      </template>
      
      <script>
      import {
        BButton, BRow, VBPopover, BCol, BCard, BFormInput
      } from 'bootstrap-vue'
      
      import FiltrarRatingSuscripcion from './FiltrarRatingSuscripcion.vue'
      import FiltrarPrecioSuscripcion from './FiltrarPrecioSuscripcion.vue'
      import FiltrarRecurrenciaSuscripcion from './FiltrarRecurrenciaSuscripcion.vue'
      import FiltrarComisionSuscripcion from './FiltrarComisionSuscripcion.vue'
      export default {
        components: {
          FiltrarPrecioSuscripcion,
          FiltrarComisionSuscripcion,
          FiltrarRecurrenciaSuscripcion,
          FiltrarRatingSuscripcion,
          BFormInput,
          BButton,
          BRow,
          BCol,
          BCard,
  
        },
        directives: {
          'b-popover': VBPopover,
        },
        props: ['tokenAuth','categorias'],
        data() {
      
      
          return {
            category:"",
            isDisabled2:true,
            searchQuery:"",
            startSearch:false,
            isDisabled:false,
            idCategoria:""
          }
        },
        computed: {
          items() {
                return this.categorias.filter(item => {
                  return item.name.toLowerCase().includes(this.searchQuery.toLowerCase());
                });
              },
      
        },
        watch: {

          
         searchQuery(value){

            if(Number(value.length) != Number("0")){
              this.startSearch=true;
            }else{
              this.startSearch=false;
            }

            }
      
        },
        mounted() {

    
        },
        methods: {
          filtrar(){

            this.$swal({

                html:
                '<div class="row"><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div><div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"><div class="loader"> </div></div><div class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7"><p style="margin:0px; font-size:16px; text-align:center; font-weight:bold">Buscando, espere</p></div><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div></div>',
                showCloseButton: false,
                showCancelButton: false,
                focusConfirm: false,
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                position: 'center',
                });


            this.$https.post('/locals/getProductsByCategoria/', { tokenAuth: this.tokenAuth, idCategoria: this.idCategoria, type: "Suscripcion"}).then(response => {
           
                                   
              if (response.data.code == 200) {

                this.$swal.close()
                  this.$eventBus.$emit('reiniciarContentProductos', response.data.productos);
                  this.$eventBus.$emit('closeModalcategoriasSuscripciones');
                  this.$eventBus.$emit('reiniciarFiltro1Suscripciones');
                  this.$eventBus.$emit('reiniciarFiltro2Suscripciones');
                  this.$eventBus.$emit('reiniciarFiltro3Suscripciones');
                  this.$eventBus.$emit('reiniciarFiltro4Suscripciones');
    
                  this.$eventBus.$emit('setCategoriaSuscripcion1', this.idCategoria);
                    this.$eventBus.$emit('setCategoriaSuscripcion2', this.idCategoria);
                    this.$eventBus.$emit('setCategoriaSuscripcion3', this.idCategoria);
                    this.$eventBus.$emit('setCategoriaSuscripcion4', this.idCategoria);

              } else {


              if (response.data.code == 401) {

                  this.$toast.error(response.data.message, {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                  })
                  
                  localStorage.removeItem('userData')


                  this.$router.push({ name: 'auth-login' })
              } else {

                this.filtrar();
                  
              }
              }
              }).catch(error => {
              this.filtrar();
              })

           

          },
          clear(){
      
            this.$refs.searchInput.focus();
            this.searchQuery = '';
      
          },

          onChangeCategory(event){

            this.idCategoria=event.target.value;
       
            
            this.filtrar();

          },
  
         
      
         
      
        },
      }
      </script>
      
      <style lang="scss">
      
      
      
      </style>
      