<template>

   <div>

    <b-row :key="componentKey">
      <b-col
      sm="12"
      md="12"
      xl="12"
      lg="12"

    >

      <b-tabs

      class="row"
      content-class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-md-0 sin_padding"
                pills
      fill  
      nav-wrapper-class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 card3 sin_padding"
         align="center"
          v-model="activoPanel"
        >
  
          <b-tab @click="SuscripcionesLoad">
  
            <template #title>
              <feather-icon
                                                icon="StarIcon"
                                                size="15"

                                                class="margin-icon-profile"
                                                />
  
              <span class="titulo_menu">Suscripciones disponibles <count-suscripciones :key="componentKeyCountSuscripciones"  :token-auth="tokenAuth"  /> </span>
            </template>

            <b-row>
              <b-col cols="12" sm="12" md="12" xl="12" lg="12" style="padding:0px"> 
                <suscripciones v-if="ifSuscripciones" :is-mobile="isMobile"  :user-id="userId" :token-auth="tokenAuth" />
  
 
                </b-col>
            </b-row>

        

          </b-tab>

          <b-tab @click="ListadoVentasLoad">
            
            <template #title>

              <feather-icon
                                                icon="ShoppingBagIcon"
                                                size="15"

                                                class="margin-icon-profile"
                                                />

            
              <span class="titulo_menu" > Ventas pendientes <count-ventas :key="componentKeyCountVentas"  :user-id="userId" :token-auth="tokenAuth"  />  </span>
          </template>
          <b-row>

         
            <b-col cols="12" sm="12" md="12" lg="1" xl="1">

          </b-col>

          <b-col cols="12" sm="12" md="12" lg="10" xl="10">

              <b-card>

                <listado-ventas v-if="ifListadoVentas" :key="componentKeyListadoVentas"  :user-id="userId" :token-auth="tokenAuth" />


            </b-card>

           
            </b-col>
            <b-col cols="12" sm="12" md="12" lg="1" xl="1">

            </b-col>

            </b-row>
       


          </b-tab>

          <b-tab @click="ListadoComprasLoad">
            
            <template #title>

              <feather-icon
                                                icon="ShoppingCartIcon"
                                                size="15"

                                                class="margin-icon-profile"
                                                />

              <span class="titulo_menu" > Compras pendientes <count-compras :key="componentKeyCountCompras" :user-id="userId" :token-auth="tokenAuth"  />  </span>
         
            </template>

            <b-row>

              <b-col cols="12" sm="12" md="12" lg="1" xl="1">

</b-col>

<b-col cols="12" sm="12" md="12" lg="10" xl="10">

                  <b-card>
                    <listado-compras v-if="ifListadoCompras" :key="componentKeyListadoCompras"  :user-id="userId" :token-auth="tokenAuth" />


                </b-card>


                </b-col>
                <b-col cols="12" sm="12" md="12" lg="1" xl="1">


                </b-col>

                </b-row>

          </b-tab>

          <b-tab @click="ListadoSuscripcionesActivasLoad">
            
            <template #title>

              <feather-icon
                                                icon="StarIcon"
                                                size="15"

                                                class="margin-icon-profile"
                                                />
              <span class="titulo_menu">Mis suscripciones activas <count-suscripciones-activas :key="componentKeyCountSuscripcionesActivas" :user-id="userId" :token-auth="tokenAuth"  /></span>
            </template>

            <b-row>

              <b-col cols="12" sm="12" md="12" lg="1" xl="1">

</b-col>

<b-col cols="12" sm="12" md="12" lg="10" xl="10">

              <b-card>
                <listado-suscripciones-activas v-if="ifListadoSuscripcionesActivas" :key="componentKeyListadoSuscripcionesActivas"  :user-id="userId" :token-auth="tokenAuth" />


            </b-card>


            </b-col>
            <b-col cols="12" sm="12" md="12" lg="1" xl="1">

            </b-col>

            </b-row>



          </b-tab>

          <b-tab @click="ListadoHistorialLoad">
            
            <template #title>

              <feather-icon
                                                icon="ListIcon"
                                                size="15"

                                                class="margin-icon-profile"
                                                />

              <span class="titulo_menu" > Historial de ventas finalizadas <count-historial :key="componentKeyCountHistorial" :user-id="userId" :token-auth="tokenAuth"  />  </span>
         
            </template>

            <b-row>

              <b-col cols="12" sm="12" md="12" lg="1" xl="1">

</b-col>

<b-col cols="12" sm="12" md="12" lg="10" xl="10">
                  <b-card>
                    <listado-historial v-if="ifListadoHistorial" :key="componentKeyListadoHistorial"  :user-id="userId" :token-auth="tokenAuth" />


                </b-card>


                </b-col>
                <b-col cols="12" sm="12" md="12" lg="1" xl="1">


                </b-col>

                </b-row>

          </b-tab>

          
  
        </b-tabs>


</b-col>
   

            </b-row>

          </div>
              
              </template>
              
              <script>
              import {
                BButton, BRow, VBPopover, BCol, BCard,BTab, BTabs
              } from 'bootstrap-vue'
              
              import Suscripciones from './Suscripciones.vue'
              import ListadoVentas from './ListadoVentas.vue'
              import ListadoCompras from './ListadoCompras.vue'
              import ListadoHistorial from './ListadoHistorial.vue'
              import ListadoSuscripcionesActivas from './ListadoSuscripcionesActivas.vue'
 
              
              export default {
                components: {
                  Suscripciones,
                  ListadoSuscripcionesActivas,
                  BButton,
                  CountSuscripcionesActivas: () => import('./CountSuscripcionesActivas.vue'),
                  CountCompras: () => import('./CountCompras.vue'),
                  CountSuscripciones: () => import('./CountSuscripciones.vue'),
                  ListadoHistorial,
                  ListadoVentas,
                  CountVentas: () => import('./CountVentas.vue'),
                  CountHistorial: () => import('./CountHistorial.vue'),
                  BRow,
                  BCol,
                  BCard,
                  BTab, BTabs,ListadoCompras
        },
        directives: {
          'b-popover': VBPopover,
        },
        props: [],
        data() {
      
          const datos_json = JSON.parse(this.$userGlobal);

          let activeTab=0;
          let ifSuscripciones=false;
          let ifListadoVentas=false;
          let ifListadoCompras=false;
          let ifListadoHistorial=false;
          let ifListadoSuscripcionesActivas=false;
      

                if(window.location.hash) {
            var hash = window.location.hash.substring(1); //Puts hash in variable, and removes the # character

            switch(hash) {
                  case "suscripciones":
                  activeTab=0;
                  ifSuscripciones=true;
                
                    break;

                    case "ventas":
                  activeTab=1;
                  ifListadoVentas=true;
                
                    break;

                    case "compras":
                  activeTab=2;
                  ifListadoCompras=true;
                
                    break;

                    case "mis_suscripciones":
                  activeTab=3;
                  ifListadoSuscripcionesActivas=true;
                
                    break;
                    case "historial":
                  activeTab=4;
                  ifListadoHistorial=true;
                
                    break;
                
                  default:
                  activeTab=0;
                  ifSuscripciones=true;
              
                }

        }else{

          activeTab=0;
          ifSuscripciones=true;
        

        }

  
          return {
      
            userId: datos_json.userId,
             tokenAuth: this.$tokenGlobal,
             isMobile:this.$isMobile,
             componentKey:100,
             componentKeyListadoVentas:200,
             componentKeyListadoCompras:300,
             componentKeyCountVentas:400,
             componentKeyCountCompras:500,
             componentKeyCountHistorial:600,
             componentKeyListadoHistorial:700,
             componentKeyCountSuscripciones:800,
             componentKeyCountSuscripcionesActivas:900,
             componentKeyListadoSuscripcionesActivas:1000,
             ifSuscripciones:ifSuscripciones,
             activoPanel:activeTab,
             ifListadoVentas:ifListadoVentas,
             ifListadoCompras:ifListadoCompras,
             ifListadoHistorial:ifListadoHistorial,
             ifListadoSuscripcionesActivas:ifListadoSuscripcionesActivas,
          }
        },
        computed: {
      
        },
        watch: {

          $route (to, from){
       
      

       var hash = to.hash.substring(1); //Puts hash in variable, and removes the # character
 
 
       switch(hash) {
             case "suscripciones":
             this.activeTab=0;
                  this.ifSuscripciones=true;
              
             
               break;

               case "ventas":
                  this.activeTab=1;
                  this.ifListadoVentas=true;
                
                    break;

                    case "compras":
                  this.activeTab=2;
                  this.ifListadoCompras=true;
                
                    break;

                    case "mis_suscripciones":
                  activeTab=3;
                  ifListadoSuscripcionesActivas=true;
                
                    break;

                    case "historial":
                  this.activeTab=4;
                  this.ifListadoHistorial=true;
                
                    break;
            
           
             default:
             this.activeTab=0;
                  this.ifSuscripciones=true;
              break;
         
           }
           
    
     }
      
        },
        activated() {


          this.$eventBus.$emit('quintoMenu1')
         this.$eventBus.$emit('quintoMenu2')

          },
        mounted() {

          this.$eventBus.$on('reiniciarMarketplace', () => {
            this.componentKey += 1
          })

          this.$eventBus.$on('reiniciarListadoVentas', () => {
            this.componentKeyListadoVentas += 1
          })

          this.$eventBus.$on('reiniciarListadoCompras', () => {
            this.componentKeyListadoCompras += 1
          })

          this.$eventBus.$on('reiniciarListadoHistorial', () => {
            this.componentKeyListadoHistorial += 1
          })

          this.$eventBus.$on('reiniciarListadoSuscripcionesActivas', () => {
            this.componentKeyListadoSuscripcionesActivas += 1
          })


          
          this.$eventBus.$on('reiniciarCountVentas', () => {
            this.componentKeyCountVentas += 1
          });

          this.$eventBus.$on('reiniciarCountSuscripciones', () => {
            this.componentKeyCountSuscripciones += 1
          });

          this.$eventBus.$on('reiniciarCountSuscripcionesActivas', () => {
            this.componentKeyCountSuscripcionesActivas += 1
          });


          

          this.$eventBus.$on('reiniciarCountCompras', () => {
            this.componentKeyCountCompras += 1
          })

          this.$eventBus.$on('reiniciarCountHistorial', () => {
            this.componentKeyCountHistorial += 1
          })

          
            
        },
        methods: {

          SuscripcionesLoad(){
            this.ifSuscripciones=true;
          },

          ListadoVentasLoad(){
            this.ifListadoVentas=true;
          },
          ListadoComprasLoad(){
            this.ifListadoCompras=true;
          },ListadoHistorialLoad(){
            this.ifListadoHistorial=true;
          },
          ListadoSuscripcionesActivasLoad(){
            this.ifListadoSuscripcionesActivas=true;
          }
 
      
      
        },
      }
      </script>
      
      <style lang="scss">
      
      
      
      </style>
      