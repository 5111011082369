<template>

    <div>
     <b-modal
       ref="modalCategoriaServicio"
       centered
       size="sm"
       hide-footer
title="Filtrar suscripciones"
       @hidden="closemodalCategoriaServicio"
     >
       <div class="d-block text-center">
        <categorias-servicios-dos :token-auth="tokenAuth" :categorias="categorias" />
 
       </div>
 
 
       </b-modal>

       <vue-bottom-dialog v-if="dialog" :indentColor="indentColor" :topRadius="radius" v-model="dialog" :height="560"  :overlayColor="overlay">
  
            <b-row>   
            
            <b-col cols="12" sm="12" md="12" lg="12" xl="12"> 

            <h4 style="margin: 0;" class="font-weight-bolder">
              Filtrar suscripciones
              <hr>
            
            </h4>

            </b-col>

            </b-row>  

            <categorias-servicios-dos :token-auth="tokenAuth" :categorias="categorias" />


          </vue-bottom-dialog>

    </div>
       
          
       
       </template>
       
       <script>
       import {
        
         BRow, BCol
       } from 'bootstrap-vue'
       
    
       import CategoriasServiciosDos from './CategoriasServiciosDos.vue'
       export default {
         components: {
              BRow, BCol,
              CategoriasServiciosDos,

              
         },
         props: ['tokenAuth','categorias','isMobile'],
         data() {
       
       
           return {
            dialog:false,
            radius:"15px",
              indentColor:"#b0b3b8",
              overlay:"#000000e6",
              body: document.getElementsByTagName('body')[0],
           }
         },
         computed: {
       
         },
         watch: {

          dialog(newValue) {

                        
                if(newValue){
                  this.body.classList.add("overflow-hidden");
                }else{

                  this.$eventBus.$emit('reiniciarmodalCategoriaServicio');
                  this.body.classList.remove("overflow-hidden");
                }



                },
       
         },
         mounted() {

   

          this.$eventBus.$on('closeModalcategoriasSuscripciones', () => {
              
        

            if(this.isMobile){
              this.dialog=false;
            }else{
              this.$refs.modalCategoriaServicio.hide();
           
            }
       
              });


              if(this.isMobile){

                this.dialog=true;

              }else{
                this.$refs.modalCategoriaServicio.show()
              }

 
        

       
         },
         methods: {
        

 
            closemodalCategoriaServicio(){
 
    
 
                 this.$eventBus.$emit('reiniciarmodalCategoriaServicio');
 
 
 
                 },

       
         },
       }
       </script>
       
       <style lang="scss">
       
       
       
       </style>
       