<template>

    <div>
        <p style="text-align:left;font-size:14px; margin-top: 15px;    line-height: 16px;"> Filtrar por rating:</p>

            <b-form-select
                v-model="filtro"
                :disabled="activarCampo"
                required
                @change="onChange($event)"
                :options="options"
                />
    </div>

      
      </template>
      
      <script>
      import {
       BFormSelect
      } from 'bootstrap-vue'
      
      
      
      export default {
        components: {
         
            BFormSelect
  
        },
        directives: {
      
        },
        props: ['tokenAuth'],
        data() {
      

      
          return {
            activarCampo:false,
            idCategoria:"No",
            filtro:"",
            options: [
            { value: '', text: 'Escoja una opción', disabled: true },
                    { value: 'All', text: 'Todos' },
                    { value: '5', text: '5 estrellas' },
                    { value: '4', text: '4 estrellas' },
                    { value: '3', text: '3 estrellas' },
                    { value: '2', text: '2 estrellas' },
                    { value: '1', text: '1 estrellas' },
            ],
      
          }
        },
        computed: {
      
        },
        watch: {
      
        },
        mounted() {

          this.$eventBus.$on('clearIdCategoriaSuscripcion1', () => {
              
            this.idCategoria="No";
     
        });

            this.$eventBus.$on('setCategoriaSuscripcion1', (id) => {
              
              this.idCategoria=id;
     
        });
              
          this.$eventBus.$on('reiniciarFiltro1Suscripciones', () => {
              
            this.filtro="";
        
              });

              this.$eventBus.$on('disabledFiltro1Suscripcion', (bandera) => {
                this.filtro="";
              this.activarCampo=bandera;
        });
      
        },
        methods: {

          onChange(opcion){

                   
this.$swal({

              html:
              '<div class="row"><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div><div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"><div class="loader"> </div></div><div class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7"><p style="margin:0px; font-size:16px; text-align:center; font-weight:bold">Buscando, espere</p></div><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div></div>',
              showCloseButton: false,
              showCancelButton: false,
              focusConfirm: false,
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              position: 'center',
              });


  this.$https.post('/locals/getProductosByRating/', { tokenAuth: this.tokenAuth, idCategoria: this.idCategoria, opcion: opcion, type: "Suscripcion"}).then(response => {
   
                            
      if (response.data.code == 200) {

          this.$swal.close()

          this.$eventBus.$emit('clearSearchSuscripcion');



          this.$eventBus.$emit('reiniciarContentProductos', response.data.productos);


          this.$eventBus.$emit('reiniciarFiltro2Suscripciones');

           this.$eventBus.$emit('reiniciarFiltro3Suscripciones');

           this.$eventBus.$emit('reiniciarFiltro4Suscripciones');


      } else {


      if (response.data.code == 401) {
          this.$swal.close()

          this.$toast.error(response.data.message, {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
          })
          
          localStorage.removeItem('userData')


          this.$router.push({ name: 'auth-login' })
      } else {

     this.onChange(opcion);
          
      }
      }
      }).catch(error => {
      this.onChange(opcion);
      })



}
      
        },
      }
      </script>
      
      <style lang="scss">
      
      
      
      </style>
      