<template>

    <b-row v-if="loading" style="margin-bottom:15px"> 

    


      <b-col  cols="10" sm="10" md="10" xl="10" lg="10">
    <h4 class="font-weight-bolder" style="text-align: left !important;    margin: 0;">
                          <feather-icon
                                    icon="ListIcon"
                                    size="21"
                                    class="color_icon"
                                    /> Mis ventas pendientes
                            </h4>


</b-col>



<b-col  cols="2" sm="2" md="2" xl="2" lg="2">
    <b-skeleton width="100%"  height="30px"></b-skeleton>


</b-col>

<b-col  cols="3" sm="3" md="3" xl="3" lg="3">
    <b-skeleton width="100%"  height="30px"></b-skeleton>


</b-col>

<b-col  cols="6" sm="6" md="6" xl="6" lg="6">
  

</b-col>


<b-col  cols="3" sm="3" md="3" xl="3" lg="3">
    <b-skeleton width="100%"  height="30px"></b-skeleton>


</b-col>



<b-col  cols="12" sm="12" md="12" xl="12" lg="12" style="margin-bottom:10px">




                  <b-skeleton-table
              :rows="2"
              :columns="columnas"
              :table-props="{ bordered: true, striped: true }"
            ></b-skeleton-table>

</b-col>

<b-col  cols="3" sm="3" md="3" xl="3" lg="3">


</b-col>

<b-col  cols="6" sm="6" md="6" xl="6" lg="6">

<b-skeleton width="100%"  height="30px"></b-skeleton>

</b-col>

<b-col  cols="3" sm="3" md="3" xl="3" lg="3">


</b-col>

<b-col  cols="3" sm="3" md="3" xl="3" lg="3">


</b-col>

<b-col  cols="2" sm="2" md="2" xl="2" lg="2">
<b-skeleton width="100%"  height="30px"></b-skeleton>


</b-col>

<b-col  cols="2" sm="2" md="2" xl="2" lg="2" align="center">
<b-skeleton width="30px" type="avatar"   height="30px"></b-skeleton>


</b-col>

<b-col  cols="2" sm="2" md="2" xl="2" lg="2">
<b-skeleton width="100%"  height="30px"></b-skeleton>


</b-col>


<b-col  cols="3" sm="3" md="3" xl="3" lg="3">


</b-col>
      
    
     </b-row>
    
     <b-row v-else-if="code200">
      
     
        <b-col  cols="10" sm="10" md="10" xl="10" lg="10">

<h4 class="font-weight-bolder" style="text-align: left !important;    margin: 0;">
      <feather-icon
                icon="ListIcon"
                size="21"
                class="color_icon"
                /> Mis ventas pendientes
        </h4>

</b-col>

<b-col  cols="2" sm="2" md="2" xl="2" lg="2" style="    text-align: right;">


<p style="cursor:pointer;    margin: 0;" @click="refresh()" > 
                                                            <feather-icon
                                                            icon="RefreshCcwIcon"
                                                            size="16"
                                                            style="margin-right:5px"
                                                            /> <span class="d-none d-sm-none d-md-inline-block d-lg-inline-block d-xl-inline-block"> Recargar </span> </p>



</b-col>

    
    <b-col  cols="12" sm="12" md="12" xl="12" lg="12" style="  overflow: auto;padding: 0px;margin-top:15px">

        
    
      <table class="table tablesorter"  id="datatable" >
                                  <thead class="thead-light">
                                    <tr>
                                      <th data-priority="1">Imagen</th>
                                      <th >Fecha/Hora</th>
                                      <th>Cliente</th>
                                       <th>Contacto</th>
                                       <th>Negocio</th>
                                       <th>Suscripción</th>
                                       <th>Monto</th>
                                       <th data-priority="2">Acción</th>
                                     
                                    </tr>
                                  </thead>
                                  <tbody>
    
    
                                    <tr v-for="transaccion in transactions" :key="transaccion.id">
                                      <td> <b-img-lazy style="width: 100% !important; " v-bind="mainProps" :src="transaccion.urlImagen1" :alt="transaccion.nameProducto"></b-img-lazy></td>
    
                                     <td> {{ transaccion.date }}</td>
    
                         
                                     <td> 

                                                                            
                                            <b-avatar
                                            size="35"
                                            :src="transaccion.photoSendMoney"
                                            variant="light-primary"
                                            ></b-avatar>
                                            <p style="margin-bottom: 0px;font-size: 12px;">{{ transaccion.displayName }}</p>

                                            </td>


                                     <td>
                                        <p style="margin-bottom:5px;" v-if="transaccion.email != ''"><b>Email: </b>{{ transaccion.email }}</p>
                                        <p style="margin-bottom:0px;" v-if="transaccion.phoneWhatsapp != ''"><b>Whatsapp: </b>{{ transaccion.phoneWhatsapp }}</p>
                                         
                                        </td>
                                        <td> @{{ transaccion.alias }}</td>
                                     <td> {{ transaccion.nameProducto }}</td>
                                     <td> ${{ transaccion.mount }}</td>

                                     <td> 
                                        <b-button
                                        size="sm"
                                                  variant="danger"
                                                 @click="rechazar(transaccion.id)"
                                                >

                                               Rechazar

                                                </b-button>
                                     </td>
                                
                        

                                        
                                    
                        
                              
    
                                    </tr>
                                  </tbody>
                                </table>
                        
    
     </b-col>
    
    
     
    
     </b-row>
    
     <b-row v-else-if="empty">
    
      <b-col  cols="12" sm="12" md="12" xl="12" lg="12">

      <h4 class="font-weight-bolder" style="text-align: left !important;    margin: 0;">
            <feather-icon
                      icon="ListIcon"
                      size="21"
                      class="color_icon"
                      /> Mis ventas pendientes
              </h4>

      </b-col>
    
     <b-col  align="center" cols="12" sm="12" md="12" xl="12" lg="12">
      <feather-icon
                icon="ShoppingBagIcon"
                size="75"
                />
                             <p class="errorTexto">
                            No se encontraron ventas pendientes
                          </p>
    
                  </b-col>
    
     <b-col  cols="12" sm="12" md="12" xl="12" lg="12" style="    text-align: center;">
    
                               <b-button   variant="primary"  @click="refresh()" > 
                                             <feather-icon
                                              icon="RefreshCcwIcon"
                                              size="16"
                                             
                                              style="margin-right:5px; color:#fff"
                                              /> Recargar listado </b-button>
    
    
     </b-col>
    </b-row>

    </template>
    
    <script>
    import {
      BButton, BRow, BCol, BCard,BSkeletonTable, BSkeleton, BAvatar, BImgLazy
    } from 'bootstrap-vue'
    import "jquery/dist/jquery.min.js";
    import $ from "jquery";
    import 'datatables.net-responsive-dt/css/responsive.dataTables.min.css'
    import 'datatables.net-responsive-dt/js/responsive.dataTables.min.js'
    
    export default {
      components: {
        BButton,
        BAvatar,
        BImgLazy,
        BSkeleton,
        BSkeletonTable,
        BRow,
        BCol,
        BCard,
      },
      props: ['userId','tokenAuth'],
      data() {
    
 
    
    
        return {
          code200: false,
          loading: true,
           empty:false,
           columnas:3,
           transactions:[],
           mainProps: {
                      center: true,
                      fluidGrow: true,
                      blank: true,
                      blankColor: '#bbb',
                      width: 600,
                      height: 150,
                    }
        }
      },
      computed: {
    
      },
      watch: {
    
      },
      mounted() {

            

    
        this.loadTable();
    
    
    
    
    
      },
      methods: {

        rechazar(id){
            this.$swal({
              title: "¿Seguro que deseas rechazar esta venta?",
              text: "El dinero pagado será devuelto al cliente y le será notificado por esta decisión.",       
              input: 'password',
              inputPlaceholder: "Ingrese su código PIN",
              inputAttributes: {
                autocapitalize: 'off',
                  maxlength: 6,
              },
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: "Rechazar",
              cancelButtonText: 'Cancelar',
              customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-secondary ml-1',
              },
              buttonsStyling: false,
              showLoaderOnConfirm: true,
              preConfirm: (pin) => {

                  if (pin != "") {

                      const data_json = { userId: this.userId,pin:pin, id:id}
                          const data_string = JSON.stringify(data_json)
              
                      
              
                          const dataEncripted = this.$encryptBackoffice.encrypt(data_string);

                          return this.$https.post('/subcripcion/rechazarVenta/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => response.data).catch(error => {
                              this.$swal.showValidationMessage(
                              error,
                              )
                          })


                  } else {
                      this.$swal.showValidationMessage('El código PIN es obligatorio')   
              }

              },
              allowOutsideClick: () => !this.$swal.isLoading(),
          }).then(result => {

              this.$swal.close()

              if(result.isConfirmed){



              
              if (result.value.code == 200) {

                  this.$eventBus.$emit('reiniciarListadoVentas')
                  this.$eventBus.$emit('reiniciarCountVentas')

                      
                  
                          this.$toast.success(result.value.message, {
                          position: 'top-right',
                          timeout: 3010,
                          closeOnClick: true,
                          pauseOnFocusLoss: false,
                          pauseOnHover: true,
                          draggable: true,
                          draggablePercent: 0.4,
                          showCloseButtonOnHover: false,
                          hideProgressBar: true,
                          closeButton: 'button',
                          icon: true,
                          rtl: false,
                          })



                      } else {

                         

                          this.$toast.error(result.value.message, {
                          position: 'top-right',
                          timeout: 3010,
                          closeOnClick: true,
                          pauseOnFocusLoss: false,
                          pauseOnHover: true,
                          draggable: true,
                          draggablePercent: 0.4,
                          showCloseButtonOnHover: false,
                          hideProgressBar: true,
                          closeButton: 'button',
                          icon: true,
                          rtl: false,
                          })

                          if (result.data.code == 401) {
                          
                          localStorage.removeItem('userData')


                          

                          // Redirect to login page
                          this.$router.push({ name: 'auth-login' })
                          }else{

                            if (result.data.code == 404) {
                              this.$eventBus.$emit('reiniciarListadoVentas');
                              this.$eventBus.$emit('reiniciarCountVentas')
                              this.$eventBus.$emit('reiniciarCountSuscripciones')
                              
                            }
                           

                          }
                      }

              }
              




          })

        },
    
        refresh() {
          this.$eventBus.$emit('reiniciarCountVentas')
          this.$eventBus.$emit('reiniciarListadoVentas')
        }, loadTable(){

            this.loading=true;
          
      
    
          this.$https.post('/subcripcion/listadoVentasPendientes/', { tokenAuth: this.tokenAuth, userId: this.userId }).then(response => {
         
                          if(response.data["code"] == 200){
                          
                         
                            this.loading=false;
                           
    
                            this.transactions= response.data["transactions"];
     
                     
                                      setTimeout(() => {
                                        $("#datatable").DataTable({
                                          responsive: true,
                                          "ordering": false,
                                          lengthMenu: [
                                            [5,10, 25, 50, -1],
                                            [5,10, 25, 50, "All"],
                                          ],
                                          pageLength: 5, 
                                             "language": {
                                                  "search": "Buscar:",
                                                  "sInfo":      "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                                                    "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                                                  "searchPlaceholder": "Ingrese algún dato",
                                                  "zeroRecords": "Lo sentimos, no se encontraron resultados",
                                                  "processing": "Buscando. Espere por favor...",
                                                  "sLengthMenu":     "Mostrar _MENU_ registros",
                                                  "oPaginate": {
                                                          "sFirst":    "Primero",
                                                          "sLast":     "Último",
                                                          "sNext":     "Siguiente",
                                                          "sPrevious": "Anterior"
                                                      },
    
                                                  }
                                        });
                                      });
    
                                         this.code200=true;
                                         this.empty=false;
    
                         
      
                              
                            }else{
    
               
                                
    
    
                                      if (response.data.code == 401) {
    
                                        this.$toast.error(response.data.message, {
                                            position: 'top-right',
                                            timeout: 3010,
                                            closeOnClick: true,
                                            pauseOnFocusLoss: false,
                                            pauseOnHover: true,
                                            draggable: true,
                                            draggablePercent: 0.4,
                                            showCloseButtonOnHover: false,
                                            hideProgressBar: true,
                                            closeButton: 'button',
                                            icon: true,
                                            rtl: false,
                                            })
    
    
                                        
                                        localStorage.removeItem('userData')


                              
                                        
                                        
    
               
    
                                        
                                        
    
                                        // Redirect to login page
                                        this.$router.push({ name: 'auth-login' })
                                      } else {
    
                                         if (response.data.code == 404) {
                                            this.loading=false;
                                            this.code200=false;
    
    
    
                                          this.empty=true;
    
                                        }else{
    
                                             this.$toast.error(response.data.message, {
                                              position: 'top-right',
                                              timeout: 3010,
                                              closeOnClick: true,
                                              pauseOnFocusLoss: false,
                                              pauseOnHover: true,
                                              draggable: true,
                                              draggablePercent: 0.4,
                                              showCloseButtonOnHover: false,
                                              hideProgressBar: true,
                                              closeButton: 'button',
                                              icon: true,
                                              rtl: false,
                                            })
    
                                            this.loadTable();
    
                                        }
                                      
    
    
                                      }
    
                            }
                     }).catch((error) => {
    
                              this.loadTable();
    
                         
                     });
        }
    
    
      },
    }
    </script>
    
    <style lang="scss">
    .btn-danger:hover:not(.disabled):not(:disabled){
      box-shadow: none !important;
    }
    
    </style>