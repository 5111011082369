<template>


          <b-row>


            <b-col cols="12" sm="12" md="12" lg="3" xl="3" >
              <b-card>

              <div style="justify-content: space-between;display: flex;">
                <p style="text-align:left;font-size:14px; margin-top: 0px;    line-height: 16px;"> Buscar suscripción</p>

                
                    <feather-icon
                    @click="loadSearch()"
                    icon="RefreshCcwIcon"
                    size="16"
                    style="cursor:pointer;margin-top: 0px;margin-right: 15px;"
                    /> 
              </div>

              <div
            class="search-input"

                        >

                        <!-- <input type="text" placeholder="Explore Vuexy...." class="form-control-input"> -->
                        <!-- @keyup.esc="escPressed" -->
                        <!-- @keyup.enter="suggestionSelected" -->
                        <b-form-input
                        ref="searchInput"
                            v-model="searchQuery"
                            placeholder="Ingrese alguna palabra para buscar ..."
                            autocomplete="off"
                            :readonly="active"

                            
                        />
                        <div
                            class="search-input-close"
                        
                        >


                    <div style="cursor:pointer;margin-right: 20px;  margin-top:0px;"  @click="clear">
                    <feather-icon icon="XIcon" size="18"  class="clear_icon2" :class="{'show_clear': searchQuery}"/>
                        
                    </div>


                    </div>

                    </div>


                  </b-card>
                </b-col>

      

          
                <b-col  v-if="loading" cols="12"  sm="12" md="12" lg="9" xl="9" style="padding:0px">
                  <b-skeleton width="20%"  height="10px"></b-skeleton>
                  <b-row>

                  <b-col cols="6" sm="6" md="4" lg="3" xl="3" >

                    <b-skeleton width="100%"  height="150px"></b-skeleton>
                      <b-skeleton width="30%"  height="10px"></b-skeleton>
                      <b-skeleton width="100%"  height="10px" style="margin-bottom: 10px;"></b-skeleton>



                                        </b-col>

                                        <b-col cols="6" sm="6" md="4" lg="3" xl="3" >

                                          <b-skeleton width="100%"  height="150px"></b-skeleton>
                      <b-skeleton width="30%"  height="10px"></b-skeleton>
                      <b-skeleton width="100%"  height="10px" style="margin-bottom: 10px;"></b-skeleton>



                                          </b-col>

                                          <b-col cols="6" sm="6" md="4" lg="3" xl="3" >

                                            <b-skeleton width="100%"  height="150px"></b-skeleton>
                      <b-skeleton width="30%"  height="10px"></b-skeleton>
                      <b-skeleton width="100%"  height="10px" style="margin-bottom: 10px;"></b-skeleton>



                                          </b-col>


                                          <b-col cols="6" sm="6" md="4" lg="3" xl="3" >

                                            <b-skeleton width="100%"  height="150px"></b-skeleton>
                      <b-skeleton width="30%"  height="10px"></b-skeleton>
                      <b-skeleton width="100%"  height="10px" style="margin-bottom: 10px;"></b-skeleton>




                                          </b-col>

                                          <b-col cols="6" sm="6" md="4" lg="3" xl="3" >

                                            <b-skeleton width="100%"  height="150px"></b-skeleton>
                      <b-skeleton width="30%"  height="10px"></b-skeleton>
                      <b-skeleton width="100%"  height="10px" style="margin-bottom: 10px;"></b-skeleton>



                      </b-col>

                      <b-col cols="6" sm="6" md="4" lg="3" xl="3" >

                        <b-skeleton width="100%"  height="150px"></b-skeleton>
                      <b-skeleton width="30%"  height="10px"></b-skeleton>
                      <b-skeleton width="100%"  height="10px" style="margin-bottom: 10px;"></b-skeleton>



                      </b-col>


                      <b-col cols="6" sm="6" md="4" lg="3" xl="3" >

                        <b-skeleton width="100%"  height="150px"></b-skeleton>
                      <b-skeleton width="30%"  height="10px"></b-skeleton>
                      <b-skeleton width="100%"  height="10px" style="margin-bottom: 10px;"></b-skeleton>




                      </b-col>
                                      
                      <b-col cols="6" sm="6" md="4" lg="3" xl="3" >

                      <b-skeleton width="100%"  height="150px"></b-skeleton>
                      <b-skeleton width="30%"  height="10px"></b-skeleton>
                      <b-skeleton width="100%"  height="10px" style="margin-bottom: 10px;"></b-skeleton>



                      </b-col>

                      <b-col cols="12" sm="12" md="12" lg="12" xl="12" align="center" style="margin-top:15px">

                        <b-skeleton width="20%"  height="40px"></b-skeleton>
                        </b-col>
                  </b-row>

                  </b-col>


                      <b-col  v-else-if="!empty" cols="12"  sm="12" md="12" lg="9" xl="9" style="padding:0px">


                        <b-row v-if="startSearch">

                          <b-row v-if="items.length != 0" >

                     

                            <b-col  cols="6" sm="6" md="6" lg="4" xl="3"  class="biografiatext2"  v-for="item in items" :key="item.id" style="  padding: 5px;  margin-bottom: 5px;">
                              <b-link    :to="{ name: 'marketplaceparam', params: { id: item.id }}" >

                                <div style="position: absolute;margin-top: 5px;margin-left: 5px;font-size: 12px;" v-if="item.hasPorcentajeGanancia === true">
                                  <b-badge variant="success">
                                        Incluye comisión <span class="d-none d-sm-none d-md-inline-block d-lg-inline-block d-xl-inline-block">por venta para promotores</span>
                                      </b-badge>
                                </div>

                                <div style="position: absolute;margin-top: 5px;margin-left: 5px;font-size: 12px;"  v-else>
                                  <b-badge variant="danger">
                                        No incluye comisión <span class="d-none d-sm-none d-md-inline-block d-lg-inline-block d-xl-inline-block">por venta para promotores</span>
                                      </b-badge>
                                </div>
                              <b-img-lazy  v-bind="mainProps" :src="item.urlImagen1" :alt="item.title"></b-img-lazy>
                              <b-row class="body_card">

                                <b-col  cols="12" sm="7" md="7" lg="7" xl="7" style="padding-left: 0px;">
                                  <p class="p_suscripciones"><span style="    font-size: 16px;">$</span>{{ item.price }} <span style="    font-size: 10px;">USD</span></p>

                                  </b-col>

                                  <b-col  cols="12" sm="5" md="5" lg="5" xl="5" style="padding-right: 0px;display:flex">
                                    <b-form-rating class="star" variant="warning"  size="sm" no-border v-model="item.rating" readonly></b-form-rating>

                                </b-col>

                                <b-col  cols="12" sm="12" md="12" lg="12" xl="12" style="padding:0px" v-b-tooltip.hover.top="item.title">

                                  <p class="p_title_productos">{{ item.title }}</p>
                               
                           
                                </b-col>

                         



                              </b-row>
                          
                            
                                </b-link>

                                </b-col>

                          
                              </b-row>

                          <b-col  v-else align="center" cols="12" sm="12" md="12" lg="12" xl="12" style="padding:0px">

                                                          
                            <svg width="100" height="100" viewBox="-20 0 190 190"  xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M38.155 140.475 48.988 62.1108l43.881 4.946 18.568 23.955-8.041 57.1092L38.155 140.475zM84.013 94.0018 88.827 71.8068l-34.781-3.5-9.854 67.1502L98.335 142.084l6.542-45.2752-20.864-2.807zM59.771 123.595C59.394 123.099 56.05 120.299 55.421 119.433 64.32 109.522 86.05 109.645 92.085 122.757 91.08 123.128 86.59 125.072 85.71 125.567 83.192 118.25 68.445 115.942 59.771 123.595zM76.503 96.4988 72.837 99.2588l-5.515-6.642L59.815 96.6468l-3.029-5.069L63.615 88.1508l-4.526-5.452L64.589 79.0188 68.979 85.4578 76.798 81.5328 79.154 86.2638l-7.047 3.783 4.396 6.452z" fill="#4a4a4a"/></svg>
                            <p style="text-align:center;font-size:16px">No se encontraron resultados.</p>


                          </b-col>

                        

                          </b-row>


                        <b-row v-else>


                          <b-col  cols="6" sm="6" md="6" lg="4" xl="3"  class="biografiatext2"  v-for="item in visiblePost" :key="item.id" style="  padding: 5px;  margin-bottom: 5px;">
                            <b-link  :to="{ name: 'marketplaceparam', params: { id: item.id }}" >

                              <div style="position: absolute;margin-top: 5px;margin-left: 5px;font-size: 12px;" v-if="item.hasPorcentajeGanancia === true">
                                  <b-badge variant="success">
                                        Incluye comisión <span class="d-none d-sm-none d-md-inline-block d-lg-inline-block d-xl-inline-block">por venta para promotores</span>
                                      </b-badge>
                                </div>

                                <div style="position: absolute;margin-top: 5px;margin-left: 5px;font-size: 12px;"  v-else>
                                  <b-badge variant="danger">
                                        No incluye comisión <span class="d-none d-sm-none d-md-inline-block d-lg-inline-block d-xl-inline-block">por venta para promotores</span>
                                      </b-badge>
                                </div>

                            <b-img-lazy  v-bind="mainProps" :src="item.urlImagen1" :alt="item.title"></b-img-lazy>
                            <b-row class="body_card">

                              <b-col  cols="12" sm="7" md="7" lg="7" xl="7" style="padding-left: 0px;">
                                <p class="p_suscripciones"><span style="    font-size: 16px;">$</span>{{ item.price }} <span style="    font-size: 10px;">USD</span></p>

                                </b-col>

                                <b-col  cols="12" sm="5" md="5" lg="5" xl="5" style="padding-right: 0px;display:flex">
                                    <b-form-rating class="star" variant="warning"  size="sm" no-border v-model="item.rating" readonly></b-form-rating>

                                </b-col>

                            

                              <b-col  cols="12" sm="12" md="12" lg="12" xl="12" style="padding:0px" v-b-tooltip.hover.top="item.title">
                                <p class="p_title_productos">{{ item.title }}</p>
                               
                                </b-col>

                              

                              </b-row>

                          </b-link>                                                  
                    

                                </b-col>


                                <b-col v-if="postVisible < publicaciones.length" style="margin-bottom:15px" cols="12" sm="12" md="12" lg="12" xl="12" align="center">
                                  <b-button
                                                                  
                                                                    variant="primary"
                                                                  
                                                                    size="md"
                                                                    @click="postVisible += step" 
                                                              
                                                                  >
                                                                Cargar más publicaciones

                                                                  </b-button>
                                                                  



                                  </b-col>
                        

                              
                        </b-row>

                        
                        </b-col>

                        <b-col v-else cols="12" sm="12" md="12" lg="12" xl="12" align="center" style="margin-top:25px">

                          <svg style="  width: 100px;"  class="icons_colors" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 480.61 480.61"><g><path d="M469.012 20.343l-2.395-6.339-6.339-2.394C439.878 3.906 418.436.0 396.547.0c-48.104.0-93.33 18.733-127.346 52.749l-48.227 48.227-107.787 5.228-90.214 90.213 77.783 24.777-28.763 28.762 16.802 16.802-37.224 57.056 105.235 105.237 57.057-37.224 16.802 16.802 28.763-28.763 24.775 77.783 90.216-90.214 5.227-107.786 48.227-48.227C477.832 161.462 493.98 86.459 469.012 20.343zM79.029 182.788l47.182-47.181 63.197-3.065-64.773 64.773L79.029 182.788zM160.805 390.623l-70.806-70.806 20.473-31.381 81.713 81.714L160.805 390.623zM345.015 354.412l-47.181 47.181-14.527-45.606 64.773-64.773L345.015 354.412zM230.665 366.202 114.419 249.956 290.414 73.961C318.764 45.612 356.455 30 396.547 30c15.957.0 31.625 2.487 46.671 7.401 17.451 53.48 3.463 112.785-36.558 152.807L230.665 366.202z"/><path d="M364.951 70.67c-12.02.0-23.32 4.681-31.819 13.18-17.546 17.545-17.546 46.094.0 63.64 8.499 8.499 19.8 13.18 31.819 13.18s23.32-4.681 31.819-13.18c17.546-17.545 17.546-46.094.0-63.64C388.272 75.351 376.971 70.67 364.951 70.67zM375.558 126.277c-2.833 2.833-6.6 4.394-10.606 4.394s-7.773-1.561-10.606-4.393c-5.849-5.849-5.849-15.365.0-21.214 2.833-2.833 6.6-4.393 10.606-4.393s7.773 1.56 10.606 4.393C381.406 110.912 381.406 120.429 375.558 126.277z"/><path d="M.013 375.612l33.999-34 21.213 21.213-33.999 33.999L.013 375.612zM105.004 480.61l-21.213-21.213 33.999-33.999 21.213 21.213L105.004 480.61zM25.031 432.878l50.122-50.122 21.213 21.213-50.122 50.122L25.031 432.878z"/></g><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></svg>

                          <p style="margin-top:15px; font-size:14px">No se encontraron suscripciones.</p>

                          <b-button

                                variant="primary"
                                size="sm"

                                @click="loadSearch"
                            >
              
                            Recargar suscripciones
              
                            </b-button>

        
              </b-col>
          
              <modal-categoria-servicios v-if="showModal" :is-mobile="isMobile"  :token-auth="tokenAuth" :categorias="categorias"  />
  
  <a  v-if="!loading2 && isMobile" class="float2"  href="#" @click="openModalFiltro" >

    <div  class="float_div">

      <feather-icon
                      icon="FilterIcon"
                      size="30"
                      style="color: white;  margin-top: 16px;"
                      />

  
                </div>


    </a>

      <a  v-if="!loading2 && !isMobile" class="float2" v-b-tooltip.hover.left="'Filtrar suscripciones'"  href="#" @click="openModalFiltro" >

        <div  class="float_div">

          <feather-icon
                          icon="FilterIcon"
                          size="30"
                          style="color: white;  margin-top: 16px;"
                          />


                    </div>


        </a>
          
          
         </b-row>

      
      </template>
      
      <script>
      import {
        BButton, BRow, VBPopover, BCol, BCard,BSkeleton, BFormInput, BImgLazy, VBTooltip, BFormRating, BLink, BBadge
      } from 'bootstrap-vue'
      


      import ModalCategoriaServicios from './ModalCategoriaServicios.vue'
      import carousel from 'vue-owl-carousel'
      export default {
        components: {
          BFormInput,
          BLink,
          
          carousel,
          BFormRating,
       
          ModalCategoriaServicios,
          BButton,
          BRow,
          BImgLazy,
          BCol,
          BCard,
          BSkeleton,
          BBadge,
        },
        directives: {
          'b-popover': VBPopover,
          'b-tooltip': VBTooltip,
        },
        props: ['userId', 'tokenAuth','isMobile'],
        data() {
          let stagePadding=0;
let margin=10;
    if(this.$isMobile){
      stagePadding=10;
      margin=5;

    }
      
          return {
            stagePadding:stagePadding,
      margin:margin,
            showModal:false,
            categorias:[],
            loading:true,
            loading2:true,
            postVisible: 8,
          step: 8,
          mainProps: {
                      center: true,
                      fluidGrow: true,
                      blank: true,
                      blankColor: '#bbb',
                      width: 200,
                      height: 100,

                    },
          startSearch:false,
          searchQuery:"",
          publicaciones:[],
          active:false,
          empty:false,
          notRecords:false,

          }
        },
        computed: {

          
        visiblePost() {
          return this.publicaciones.slice(0, this.postVisible)
          },

        items() {
              return this.publicaciones.filter(item => {
                return item.title.toLowerCase().includes(this.searchQuery.toLowerCase());
              });

            
            },
      
        },
        watch: {

          searchQuery(value){

              if(Number(value.length) != Number("0")){
                this.startSearch=true;
              }else{
                this.startSearch=false;
              }

              }
      
        },
        created(){
      
        },
        mounted() {

       
          this.loadSearch();
          this.loadCategorias();

          this.$eventBus.$on('reiniciarmodalCategoriaServicio', () => {
              
            this.showModal=false;
            });

            this.$eventBus.$on('clearSearchSuscripcion', () => {
              
              this.searchQuery="";
              });

            this.$eventBus.$on('reiniciarContentProductos', (productos) => {

              this.searchQuery="";

           
              if(Number(productos.length) > Number("0")){
                this.empty=false;
                this.publicaciones=productos;
                this.active=false;
  

              }else{

                this.empty=true;
                this.active=true;
                this.publicaciones=[];
    

              }
              
              this.showModal=false;
              });

  
       
      
        },
        methods: {

          clear(){
    
          this.$refs.searchInput.focus();
          this.searchQuery = '';

        },

          loadSearch(){

            this.$eventBus.$emit('reiniciarCategoriaServicios1');

            this.$eventBus.$emit('disabledFiltro1Suscripcion', true);
            this.$eventBus.$emit('disabledFiltro2Suscripcion', true);
            this.$eventBus.$emit('disabledFiltro3Suscripcion', true);
            this.$eventBus.$emit('disabledFiltro4Suscripcion', true);
            this.$eventBus.$emit('clearIdCategoriaSuscripcion1');
            this.$eventBus.$emit('clearIdCategoriaSuscripcion2');
            this.$eventBus.$emit('clearIdCategoriaSuscripcion3');
            this.$eventBus.$emit('clearIdCategoriaSuscripcion4');

            
              this.postVisible= 8;
              this.step= 8;

              this.active=true;
              this.loading=true;


                        this.$https.post('/locals/getProductsAll/', { tokenAuth: this.tokenAuth, type: "Suscripcion"}).then(response => {

                 
                          
                     
                        if (response.data.code == 200) {
                        
                            this.loading = false;


                            this.active = false;
                            this.publicaciones= response.data.productos;
                     
                           
                            this.empty=false;

                            this.$eventBus.$emit('disabledFiltro1Suscripcion', false);
                            this.$eventBus.$emit('disabledFiltro2Suscripcion', false);
                            this.$eventBus.$emit('disabledFiltro3Suscripcion', false);



                        } else {

                      
                        if (response.data.code == 401) {

                            this.$toast.error(response.data.message, {
                            position: 'top-right',
                            timeout: 3010,
                            closeOnClick: true,
                            pauseOnFocusLoss: false,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.4,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: 'button',
                            icon: true,
                            rtl: false,
                            })
                            
                            localStorage.removeItem('userData')


                            this.$router.push({ name: 'auth-login' })
                        } else {

                            if(response.data.code == 404){

                          

                            this.active = true;
                            this.loading= false;
                            this.empty=true;
                            this.publicaciones=[];
                            this.$eventBus.$emit('disabledFiltro1Suscripcion', true);
                            this.$eventBus.$emit('disabledFiltro2Suscripcion', true);
                            this.$eventBus.$emit('disabledFiltro3Suscripcion', true);
                          

                          
                            }else{

                    
                                this.loadSearch();
                            

                            
                            }
                            
                        }
                        }
                        }).catch(error => {
                        this.loadSearch();
                        })

              },
  
          openModalFiltro(){
  
            this.showModal=true;
  
          },
          loadCategorias(){
  
            this.loading2=true;
            
  
  
            this.$https.post('/locals/getCategoriasProductos/', {  type: "Servicio"}).then(response => {
                    
                            
                    if (response.data.code == 200) {
             
                      this.loading2=false;
                        this.categorias= response.data.categorias;
  
  
                    } else {
  
                  
                    if (response.data.code == 401) {
  
                        this.$toast.error(response.data.message, {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                        })
                        
                        localStorage.removeItem('userData')
  
  
                        this.$router.push({ name: 'auth-login' })
                    } else {
  
                        this.loadCategorias();
                        
                    }
                    }
                    }).catch(error => {
                    this.loadCategorias();
                    })
          }
  
      
         
      
        },
      }
      </script>
      
      <style lang="scss">
      
      
      
      </style>
      